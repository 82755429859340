<template>
  <div>

    <div class="form">
      <el-form :inline="true" label-width="90px" :model="form">
        <el-row>
          <el-col :span="7">
            <el-form-item label="实体卡编号" prop="cardNo">
              <el-input v-model="form.cardNo" clearable placeholder="请输入实体卡编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="form.productName" clearable placeholder="请输入产品名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="产品类别" prop="productCategory">
              <el-select v-model="form.productCategory" clearable placeholder="请选择产品类别">
                <el-option v-for="item in $store.state.category"
                           :key="item.dictKey"
                           :label="item.dictValue" :value="item.dictKey"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-button type="primary" @click="onSubmit(form)">查询</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="实体卡状态" prop="cardStatus">
              <el-select v-model="form.cardStatus" clearable placeholder="请选择实体卡状态">
                <el-option v-for="item in cardState"
                           :key="item.dictKey"
                           :label="item.dictValue" :value="item.dictKey"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="实体卡价格" prop="amount">
              <el-input v-model="form.amount" clearable placeholder="请输入实体卡价格"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="生成日期" prop="username">
              <el-date-picker
                  v-model="form.time"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>


    <div class="list" style="min-height: calc(100vh - 276px)">
      <div class="operation">
        <el-button @click="addClick" size="small" type="primary">生成实体卡</el-button>
        <el-button @click="cardExport(form)" size="small" type="primary">导出</el-button>
      </div>

      <el-table
          :data="table"
          style="width: 100%">

        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table
                :data="scope.row.cardVOList"
                style="width: 100%">
              <el-table-column
                  prop="cardNo"
                  align="center"
                  label="实体卡号">
                <template slot-scope="scope">
                  <span class="blue" @click="deta(scope.row)" >{{ scope.row.cardNo }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="createTime"
                  align="center"
                  label="创建时间">
              </el-table-column>
              <el-table-column
                  prop="cardStatus"
                  align="center"
                  label="实体卡状态">
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>

        <el-table-column
            prop="batchNo"
            align="center"
            label="批次号">
        </el-table-column>
        <el-table-column
            prop="productName"
            align="center"
            label="产品名称">
        </el-table-column>
        <el-table-column
            prop="productCategory"
            align="center"
            label="产品类别">
        </el-table-column>
        <el-table-column
            prop="price"
            align="center"
            label="产品价格">
        </el-table-column>
        <el-table-column
            prop="createTime"
            align="center"
            label="生成日期">
        </el-table-column>
        <el-table-column
            prop="amount"
            align="center"
            label="实际价格">
        </el-table-column>
        <el-table-column
            prop="cardCount"
            align="center"
            label="数量">
        </el-table-column>
        <el-table-column
            fixed="right"
            align="center"
            label="操作">
          <template slot-scope="scope">
            <el-popconfirm
                title="是否要删除此批次号下的所有实体卡？"
                @confirm="del(scope.row)"
            >
              <el-button slot="reference" type="text" size="small" >删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>


    <el-dialog
        title="生成实体卡"
        :visible.sync="add"
        width="30%"
        :close-on-click-modal="false"
        @close="addClose"
        center>

      <div class="dia">
        <el-form label-width="90px"
                 :hide-required-asterisk="true"
                 ref="add_form"
                 @submit.native.prevent
                 :model="add_form"
                 :rules="add_rules" >
          <el-form-item label="产品类别" prop="productCategory">
            <el-select v-model="add_form.productCategory" clearable placeholder="请选择产品类别" @change="productCategory_change" >
              <el-option v-for="item in $store.state.category"
                         :key="item.dictKey"
                         :label="item.dictValue" :value="item.dictKey"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品" prop="productId">
            <el-select v-model="add_form.productId" clearable placeholder="请选择产品" @change="productId_change">
              <el-option v-for="item in productSelect"
                         :key="item.dictKey"
                         :label="item.dictValue" :value="item.dictKey"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品价格" prop="productAmount">
            <el-input v-model="add_form.productAmount" disabled clearable placeholder="请输入实体卡价格"></el-input>
          </el-form-item>
          <el-form-item label="实体卡价格" prop="amount">
            <el-input v-model="add_form.amount" clearable placeholder="请输入实体卡价格"></el-input>
          </el-form-item>
          <el-form-item label="生成数量" prop="count">
            <el-input v-model="add_form.count" clearable placeholder="请输入生成数量"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="button">
              <el-button @click="addClose">取消</el-button>
              <el-button type="primary" native-type="submit" @click="onSubmitAdd(add_form)" >确认</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>


  </div>
</template>

<script>
export default {
  name: "Card",
  data() {
    var validatorAmount = (rule, value, callback) => {
      if(value < 0) {
        callback(new Error("实体卡价格不能小于0"));
      }else {
        callback();
      }
    };
    return {
      form: {
        pageNum: 1,
        pageSize: 10,
        cardNo: null, // 实体卡编号
        cardStatus: null, // 实体卡状态
        productName: null, // 产品名称
        productCategory: null, // 产品类别
        amount: null, // 价格
        beginDate: null, // 开始日期
        endDate: null, // 结束日期
        time: null, // 时间
      },
      table: [],
      total: 0,
      cardState: [
        {
          dictKey: '0',
          dictValue: "未售出"
        },
        {
          dictKey: '1',
          dictValue: "已售出"
        },
        {
          dictKey: '2',
          dictValue: "已激活"
        },
        {
          dictKey: '3',
          dictValue: "已核销"
        },
      ],
      add: false,
      productSelect: [],
      add_form: {
        id: null,
        productCategory: null, // 产品类别
        amount: null, // 实际金额
        count: null, // 数量
        productId: null, // 产品ID
        productAmount: null,
      },
      add_rules: {
        productCategory: [
            { required: true, message: '请选择产品类别', trigger: 'change' },
        ],
        amount: [
            { required: true, message: '请输入实体卡价格', trigger: 'change' },
            { validator: validatorAmount, trigger: 'blur' }
        ],
        count: [
            { required: true, message: '请输入生成数量', trigger: 'change' },
        ],
        productId: [
            { required: true, message: '请选择产品', trigger: 'change' },
        ],
      }
    }
  },
  created() {
      this.getList(this.form);
  },
  methods: {
    del(e) {
      console.log(e);
      this.$del("card",e.batchNo,true)
        .then(res => {
          if(res) {
            console.log(res);
            this.$message.success(res.message);
            this.getList(this.form);
          }
        })
    },
    onSubmitAdd(y) {
      this.$refs["add_form"].validate((valid) => {
        if(valid) {
          console.log(y);

          this.$post("card/create",y)
            .then(res => {
              if(res) {
                this.$message.success(res.message);
                this.form.pageNum= 1;
                this.getList(this.form);

                this.addClose();

              }
            })


        }
      })
    },
    productId_change(e) {

      for (let i = 0; i < this.productSelect.length; i++) {
        if(this.productSelect[i].dictKey == e) {
          this.add_form.productAmount= this.productSelect[i].price;
          return;
        }else {
          this.add_form.productAmount= null;
        }
      }

    },
    productCategory_change(e) {

      if(e) {
        this.$get("card/getProduct",e,true)
            .then(res => {
              if(res) {

                this.productSelect= res.data;
                if(res.data.length === 0) {
                  this.add_form.productId= null;
                  this.add_form.productAmount= null;
                }

              }
            })
      }else {
        this.productSelect= [];
        this.add_form.productId= null;
        this.add_form.productAmount= null;
      }


    },
    addClose() {
      this.add= false;
      this.$refs["add_form"].resetFields();
    },
    addClick() {
      this.add= true;
    },
    cardExport(y) {

      console.log(y)

      if(y.time) {
        y.beginDate= y.time[0];
        y.endDate= y.time[1];
      }else {
        y.beginDate= null;
        y.endDate= null;
      }

      this.$file("card/export",y)
        .then(() => {

        })

    },
    deta(y) {
      this.$router.push({
        path: "/CardDeta",
        query: {
          id: y.cardId
        }
      })
    },
    onSubmit(y) {

      if(y.time) {
        y.beginDate= y.time[0];
        y.endDate= y.time[1];
      }else {
        y.beginDate= null;
        y.endDate= null;
      }

      this.getList(y);
    },
    getList(y) {
      this.$post("card",y)
        .then(res => {

          if(res) {
            console.log(res);

            this.table= res.data.list;
            this.total= res.data.total;

          }

        })

    },
    handleSizeChange(e) {
      // this.form.pageNum= 1;
      this.form.pageSize= e;
      this.getList(this.form);
    },
    handleCurrentChange(e) {
      this.form.pageNum= e;
      this.getList(this.form);
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-date-editor .el-range-separator{
  padding: 0;
}
</style>
